import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    // Se o usuário não estiver autenticado, redireciona para a página de login
    if (!user) {
        return <Navigate to="/painel/login" />;
    }

    // Se o usuário estiver autenticado, renderiza o componente passado como `element`
    return element;
};

export default ProtectedRoute;
