import React from 'react';
import { MaskedInput } from 'antd-mask-input';
import './InputPhone.css';

const InputPhone = ({ placeholder, onValueChange, value }) => {
  return (
    <div className="inputphone-container">
      <div className="input-wrapper">
        <MaskedInput
          className="input-field"
          mask="(00) 00000-0000"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          aria-label="phone"
        />
      </div>
    </div>
  );
};

export default InputPhone;