import React, { useState, useEffect } from 'react';
import InputPhone from '../../components/landingpage/InputStyled/InputPhone/InputPhone';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../GiftMapPage.css';

const PhoneStep = ({ onNext, onBack, phone: initialPhone, setPhone, email, isReturning }) => {
  const [phone, setLocalPhone] = useState(initialPhone || '');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [messageElements, setMessageElements] = useState([]);
  const [introPhoneTyping, setIntroPhoneTyping] = useState(false);
  const [showInputPhone, setShowInputPhone] = useState(false);

  const intro_phone = "Agora informe seu número de telefone.";

  const validatePhone = (phone) => {
    const phoneNumbersOnly = phone.replace(/\D/g, '');
    return phoneNumbersOnly.length === 11;
  };

  useEffect(() => {
    setIsValidPhone(validatePhone(phone));
  }, [phone]);

  useEffect(() => {
    if (isReturning) {
      // Se o usuário está retornando, apenas exiba o input
      setMessageElements([<h3 key="intro-phone" className="intro-phone">{intro_phone}</h3>]);
      setIntroPhoneTyping(false);
      setShowInputPhone(true);
    } else {
      // Se o usuário está indo para a etapa pela primeira vez, digite o texto
      setIntroPhoneTyping(true);
      const typeIntroPhone = (charIndex = 0) => {
        if (charIndex < intro_phone.length) {
          setMessageElements([React.createElement('h3', { key: 'intro-phone', className: 'intro-phone' }, intro_phone.slice(0, charIndex + 1))]);
          setTimeout(() => typeIntroPhone(charIndex + 1), 30);
        } else {
          setIntroPhoneTyping(false);
          setShowInputPhone(true);
        }
      };

      typeIntroPhone();
    }
  }, [intro_phone, isReturning]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isValidPhone) {
        setPhone(phone);
        onNext();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [phone, isValidPhone, onNext, setPhone]);

  return (
    <div className="message-wrapper">
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        {messageElements}
        {introPhoneTyping && <span className="typing-indicator">Digitando...</span>}
        {!introPhoneTyping && showInputPhone && (
          <>
            <InputPhone
              placeholder="Digite seu telefone"
              value={phone}
              onValueChange={setLocalPhone}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon' /> Voltar 
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setPhone(phone);
                  onNext();
                }}
                className='btn-send'
                disabled={!isValidPhone}
              >
                Continuar <ArrowRightOutlined className='btn-icon' />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PhoneStep;
