import React, { useState, useEffect } from 'react';
import { Avatar, Dropdown, message } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const UserMenu = () => {
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [nome_completo, setNome_completo] = useState('');
    const [perfil_imagem_url, setPerfil_imagem_url] = useState('');

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUsername(storedUser.user.username);
            setNome_completo(storedUser.user.nome_completo);
            setPerfil_imagem_url(storedUser.user.perfil_imagem_url);
        }
    }, []);

    const handleOpenChange = (open) => {
        setOpen(open);
    };

    const handleLogout = () => {
        // Remove o token e dados do usuário do localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        
        // Redireciona para a página de login e substitui o estado do histórico
        window.location.href = '/painel/login';
        message.success('Você foi desconectado com sucesso.');
    };

    // Função para gerar as iniciais do nome, com verificação de nome válido
    const getInitials = (name) => {
        if (!name) return ''; // Se o nome for indefinido ou vazio, retorna uma string vazia
        const nameArray = name.split(' ');
        const initials = nameArray.map(part => part[0]).slice(0, 2).join('');
        return initials.toUpperCase();
    };

    const initials = getInitials(nome_completo);

    const menuItems = [
        {
            key: 'profile',
            label: (
                <Link to={`/dashboard/meu-perfil/${username}`}>Meu perfil</Link>
            ),
        },
        {
            key: 'logout',
            label: <Link onClick={handleLogout}>Sair</Link>,
        },
    ];

    return (
        <Dropdown
            menu={{ items: menuItems }}
            trigger={['click']}
            onOpenChange={handleOpenChange}
            open={open}
        >
            <div style={{ display: 'flex', alignItems: 'center', color: '#ddd4a2' }}>
                {perfil_imagem_url ? (
                    // Mostra o avatar do usuário se ele tiver uma imagem
                    <Avatar
                        src={perfil_imagem_url}
                        className="user-avatar"
                        size="large"
                        alt="User Avatar"
                    />
                ) : (
                    // Avatar com as iniciais se não houver imagem de perfil
                    <Avatar
                        style={{
                            backgroundColor: '#DDD4A2',
                            color: '#17665e',
                            fontSize: '15px',
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        size="large"
                    >
                        {initials || 'U'} {/* Mostra 'U' como padrão se as iniciais não estiverem disponíveis */}
                    </Avatar>
                )}
                <div className="user-details" style={{ marginLeft: 8 }}>
                    <div>{nome_completo || 'Usuário'}</div> {/* Nome padrão se nome_completo for indefinido */}
                </div>
                {open ? <UpOutlined style={{ marginLeft: 8 }} /> : <DownOutlined style={{ marginLeft: 8 }} />}
            </div>
        </Dropdown>
    );
};

export default UserMenu;
