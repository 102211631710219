import axios from 'axios';

// Criação da instância do axios
const api = axios.create({
  baseURL: 'https://api-cliente-mimoravel.onrender.com/api',
  timeout: 10000, // Tempo limite para requisições (10 segundos)
});

// Função para obter o token de autenticação do armazenamento (exemplo com localStorage)
const getAuthToken = () => {
  return localStorage.getItem('authToken'); // Substitua por sessionStorage se preferir
};

// Interceptador para adicionar cabeçalhos de autenticação
api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptador para lidar com respostas e erros
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Exemplo de tratamento de erros global
    if (error.response && error.response.status === 401) {
      // Redirecionar ou mostrar mensagem de erro para não autorizado
      console.error('Não autorizado. Redirecionando para login...');
      // Possível redirecionamento para a página de login ou logout
    } else if (error.response && error.response.status >= 500) {
      // Mensagem de erro para problemas no servidor
      console.error('Erro no servidor. Tente novamente mais tarde.');
    }
    return Promise.reject(error);
  }
);

export default api;
