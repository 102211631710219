import React from 'react';
import { Input } from 'antd';
import './InputRelation.css';

const InputRelation = ({ placeholder, onValueChange, value, maxLength }) => {
  return (
    <div className="inputrelation-container">
      <div className="input-wrapper">
        <Input
          className="input-field"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          aria-label="relação"
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};

export default InputRelation;
