import React, { useState, useEffect } from 'react';
import InputCity from '../../components/landingpage/InputStyled/InputCity/InputCity';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../GiftMapPage.css';

const CityStep = ({ onNext, onBack, city: initialCity, setCity, isReturning }) => {
  const [city, setLocalCity] = useState(initialCity || '');
  const [isValidCity, setIsValidCity] = useState(false);
  const [messageElements, setMessageElements] = useState([]);
  const [introCityTyping, setIntroCityTyping] = useState(false);
  const [showInputCity, setShowInputCity] = useState(false);

  const intro_city = "Em qual cidade você está?";

  useEffect(() => {
    if (isReturning) {
      // Se o usuário está retornando, exiba o texto de introdução e o input
      setMessageElements([<h3 key="intro-city" className="intro-city">{intro_city}</h3>]);
      setIntroCityTyping(false);
      setShowInputCity(true);
    } else {
      // Se o usuário está indo para a etapa pela primeira vez, digite o texto
      setIntroCityTyping(true);
      const typeIntroCity = (charIndex = 0) => {
        if (charIndex < intro_city.length) {
          setMessageElements([React.createElement('h3', { key: 'intro-city', className: 'intro-city' }, intro_city.slice(0, charIndex + 1))]);
          setTimeout(() => typeIntroCity(charIndex + 1), 30);
        } else {
          setIntroCityTyping(false);
          setShowInputCity(true);
        }
      };

      typeIntroCity();
    }
  }, [intro_city, isReturning]);

  useEffect(() => {
    setIsValidCity(city.trim().length > 0); // Consider city valid if not empty
  }, [city]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isValidCity) {
        setCity(city); // Atualizar a cidade no estado do componente pai
        onNext();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [city, isValidCity, onNext, setCity]);

  const handleCityChange = (value) => {
    setLocalCity(value);
    setIsValidCity(value.trim().length > 0); // Consider city valid if not empty
  };

  return (
    <div className="message-wrapper">
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        {messageElements}
        {introCityTyping && <span className="typing-indicator">Digitando...</span>}
        {!introCityTyping && showInputCity && (
          <>
            <InputCity
              placeholder="Digite sua cidade"
              value={city}
              onValueChange={handleCityChange}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon'/> Voltar 
              </Button>
              <Button type="primary" onClick={() => {
                setCity(city); // Atualizar a cidade no estado do componente pai
                onNext();
              }} className='btn-send' disabled={!isValidCity}>
                Continuar <ArrowRightOutlined className='btn-icon'/>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CityStep;
