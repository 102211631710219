import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingSpinner = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  return (
    <div className="loading-container">
      <Spin indicator={antIcon} size="large" className='icon'/>
    </div>
  );
};

export default LoadingSpinner;