import React from 'react';
import { Input } from 'antd';
import './InputRelation.css';

const { TextArea } = Input;

const InputRelationTextArea = ({ placeholder, onValueChange, value, rows }) => {
  return (
    <div className="inputrelation-textarea-container">
      <div className="input-wrapper">
        <TextArea
          className="input-textarea"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          rows={rows}
          maxLength={500} // You can adjust the maxLength as needed
          showCount
        />
      </div>
    </div>
  );
};

export default InputRelationTextArea;
