import React, { useState, useEffect } from 'react';
import InputRelation from '../../../components/landingpage/InputStyled/InputRelation/InputRelation';
import InputRelationTextArea from '../../../components/landingpage/InputStyled/InputRelation/InputRelationTextArea';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../../GiftMapPage.css';

const RelationStep = ({ onNext, onBack, relation1: initialRelation1, relation2: initialRelation2, setRelation1, setRelation2, isReturning }) => {
  const [relation1, setLocalRelation1] = useState(initialRelation1 || '');
  const [relation2, setLocalRelation2] = useState(initialRelation2 || '');
  const [typing, setTyping] = useState(false);
  const [showFirstInput, setShowFirstInput] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);
  const [showSecondInput, setShowSecondInput] = useState(false);
  const [firstMessage, setFirstMessage] = useState('');
  const [secondMessage, setSecondMessage] = useState('');

  const intro_relation = "O presente que você está procurando é para alguém muito especial para você ou é para alguém que você não conhece bem?";
  const intro_relation2 = "Descreva o nível de relacionamento com o presenteado para que o presente transmita o sentimento e a mensagem correta.";

  useEffect(() => {
    if (!isReturning) {
      const typeMessage = async (text, setMessageState, callback) => {
        for (let i = 0; i <= text.length; i++) {
          setMessageState(text.slice(0, i));
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
        if (callback) {
          callback();
        }
      };

      const animateText = async () => {
        setTyping(true);
        await typeMessage(intro_relation, setFirstMessage, () => {
          setShowFirstInput(true);
          setTyping(false);
        });
        
        setTimeout(() => {
          setTyping(true);
          setShowSecondText(true);
          typeMessage(intro_relation2, setSecondMessage, () => {
            setShowSecondInput(true);
            setTyping(false);
          });
        }, 500); // Delay before typing the second text
      };

      animateText();
    } else {
      // Fade-in effect when returning
      setFirstMessage(intro_relation);
      setSecondMessage(intro_relation2);
      setShowFirstInput(true);
      setShowSecondText(true);
      setShowSecondInput(true);
    }
  }, [isReturning]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && relation1.trim().length > 0 && relation2.trim().length > 0) {
        setRelation1(relation1);
        setRelation2(relation2);
        onNext();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [relation1, relation2, onNext, setRelation1, setRelation2]);

  // Sync local state with parent state when component mounts or initialRelation props change
  useEffect(() => {
    setLocalRelation1(initialRelation1 || '');
    setLocalRelation2(initialRelation2 || '');
  }, [initialRelation1, initialRelation2]);

  const isButtonDisabled = !(relation1.trim().length > 0 && relation2.trim().length > 0);

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        <h3>{firstMessage}</h3>
        {showFirstInput && (
          <InputRelation
            placeholder="Digite o nível de importância da pessoa"
            value={relation1}
            onValueChange={(value) => {
              setLocalRelation1(value);
              setRelation1(value); // update the parent state immediately
            }}
          />
        )}
        {showSecondText && (
          <>
            <br />
            <h3>{secondMessage}</h3>
          </>
        )}
        {showSecondInput && (
          <>
            <InputRelationTextArea
              placeholder="Descreva o nível de relacionamento"
              value={relation2}
              onValueChange={(value) => {
                setLocalRelation2(value);
                setRelation2(value); // update the parent state immediately
              }}
              rows={4}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon' /> Voltar
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onNext();
                }}
                className='btn-send'
                disabled={isButtonDisabled}
              >
                Continuar <ArrowRightOutlined className='btn-icon' />
              </Button>
            </div>
          </>
        )}
        {typing && <span className="typing-indicator">Digitando...</span>}
      </div>
    </div>
  );
};

export default RelationStep;
