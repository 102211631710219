import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import './InputCity.css';

const InputCity = ({ placeholder, onValueChange, value }) => {
  const [options, setOptions] = useState([]);

  const fetchCities = async (query) => {
    if (!query) {
      setOptions([]);
      return;
    }

    try {
      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome`
      );
      const filteredCities = response.data
        .filter((city) =>
          city.nome.toLowerCase().includes(query.toLowerCase())
        )
        .map((city) => ({
          value: city.nome,
          label: city.nome,
        }));
      setOptions(filteredCities);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
      setOptions([]);
    }
  };

  const handleSearch = (searchText) => {
    fetchCities(searchText);
  };

  const handleSelect = (selectedValue) => {
    onValueChange(selectedValue);
  };

  return (
    <div className="inputcity-container">
      <div className="input-wrapper">
        <AutoComplete
          options={options}
          className="input-complete"
          onSearch={handleSearch}
          onSelect={handleSelect}
          placeholder={placeholder}
          value={value}
          onChange={onValueChange}
          aria-label="cidade"
        >
          <Input
            className="input-field"
            suffix={
              value ? (
                <CloseCircleOutlined
                  onClick={() => onValueChange('')}
                  style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                />
              ) : (
                <DownOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              )
            }
            allowClear={false}
          />
        </AutoComplete>
      </div>
    </div>
  );
};

export default InputCity;
