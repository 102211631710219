import React, { useState } from 'react'; // Importa useState
import { Form, Input, Button, Typography, Row, Col, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { loginUser } from '../../../services/userService';
import './auth.css';

const { Text, Link } = Typography;

const LoginPage = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false); // Adiciona o estado loading

    const onFinish = async (values) => {
        const { email, senha } = values;
        setLoading(true); // Ativa o estado de loading

        try {
            const data = await loginUser(email, senha);
            // Salvar token ou dados do usuário no localStorage ou contexto
            localStorage.setItem('user', JSON.stringify(data)); 
            localStorage.setItem('username', data.user.username);
            localStorage.setItem('nome_completo', data.user.nome_completo);
            message.success('Login bem-sucedido!');
            // Redirecionar para a página principal ou dashboard
            window.location.href = '/dashboard/pagina-inicial';
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Erro ao fazer login.';
            message.error(errorMessage);
        } finally {
            setLoading(false); // Desativa o estado de loading
        }
    };

    return (
        <Row className="login-container" align="middle" justify="center">
            <Col>
                <div className="logo">
                    <img src="/logo.webp" alt="Mimoravel Logo" />
                </div>
                <div className="login-box">
                    <h4 className="login-title font-weight-600">Entre com a sua conta</h4>
                    <Form
                        form={form} // Adiciona o form
                        name="login_form"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Por favor, insira seu e-mail!' },
                                { type: 'email', message: 'Insira um e-mail válido!' },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} placeholder="nome@email.com" />
                        </Form.Item>

                        <Form.Item
                            name="senha"
                            rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Senha" />
                        </Form.Item>

                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                className="login-form-button"
                                loading={loading} // Adiciona o estado de loading
                            >
                                Entrar
                            </Button>
                        </Form.Item>
                    </Form>
                    <Text className='font-weight-400'>
                        <Link href="/recuperar-senha" className='font-weight-600'>Esqueci minha senha</Link>
                    </Text><br/>
                    <Text className='font-weight-400'>
                        Não tem conta? <Link href="/painel/register" className='font-weight-600'>Cadastrar</Link>
                    </Text>
                </div>
            </Col>
        </Row>
    );
};

export default LoginPage;
