import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import '../GiftMapPage.css';

const IntroStep = ({ onNext }) => {
  const [messageElements, setMessageElements] = useState([]);
  const [typing, setTyping] = useState(true);

  const introMessages = {
    intro_hi: "Olá! Eu sou a Mimo.",
    intro_perfect: "Como sua assistente virtual, meu objetivo é ajudar você a descobrir o presente perfeito com muito carinho.",
    // intro_jorney: "Pronto para começar? Clique em 'Começar' e responda algumas perguntas para que possamos encontrar o presente ideal para você!"
  };

  const { intro_hi, intro_perfect } = introMessages;

  useEffect(() => {
    const messages = [
      { tag: 'h1', className: 'intro-h1', text: intro_hi },
      { tag: 'h2', className: 'intro-h2', text: intro_perfect },
      // { tag: 'h3', className: 'intro-h3', text: intro_jorney }
    ];

    setMessageElements([]);
    setTyping(true);

    const typeMessage = (messageIndex = 0, charIndex = 0) => {
      if (messageIndex < messages.length) {
        const { tag, className, text } = messages[messageIndex];
        const currentText = text.slice(0, charIndex + 1);

        setMessageElements(prev => [
          ...prev.slice(0, messageIndex),
          React.createElement(tag, { key: messageIndex, className: className }, currentText),
          React.createElement('br', { key: `br${messageIndex}` })
        ]);

        if (charIndex < text.length - 1) {
          setTimeout(() => typeMessage(messageIndex, charIndex + 1), 30);
        } else {
          setTimeout(() => typeMessage(messageIndex + 1, 0), 200);
        }
      } else {
        setTyping(false);
      }
    };

    typeMessage();
  }, [intro_hi, intro_perfect]);

  // Handle key press event
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !typing) {
      onNext();
    }
  }, [typing, onNext]);

  useEffect(() => {
    // Add keydown event listener when component is mounted
    window.addEventListener('keydown', handleKeyPress);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="message-wrapper">
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        {messageElements}
        {typing && <span className="typing-indicator">Digitando...</span>}
      </div>
      {!typing && (
        <Button type="primary" onClick={onNext} className='btn-landing'>
          Começar <SendOutlined className='btn-icon'/>
        </Button>
      )}
    </div>
  );
};

export default IntroStep;
