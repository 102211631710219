import React, { Suspense, useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardHeader from '../../components/admin/DashboardHeader';
import DashboardSidebar from '../../components/admin/DashboardSidebar';
import DashboardFooter from '../../components/admin/DashboardFooter';
import Loading from '../../components/LoadingSpinner';
import ProtectedRoute from '../../components/ProtectedRoute';

const PaginaInicial = React.lazy(() => import('../Admin/DashPaginaInicial'));
const MapaPresente = React.lazy(() => import('../Admin/DashGiftMapPage'));
const GiftMapDetailsPage = React.lazy(() => import('../Admin/GiftMapDetailsPage'));
const Tema = React.lazy(() => import('../Admin/config/DashTema'));
const ProfilePage = React.lazy(() => import('../Admin/profile/ProfilePage'));

const { Content } = Layout;

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      setTimeout(() => {
        setLoading(false);
        setInitialLoad(false);
      }, 1000);
    }
  }, [initialLoad]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
<DashboardSidebar/>
      <Layout>
        <DashboardHeader />
        <Content style={{ padding: '24px' }}>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Navigate to="pagina-inicial" />} />
              <Route
                path="pagina-inicial"
                element={<ProtectedRoute element={<PaginaInicial />} />}
              />
              <Route
                path="mapa-presente"
                element={<ProtectedRoute element={<MapaPresente />} />}
              />
              <Route
                path="mapa-presente/:id"
                element={<ProtectedRoute element={<GiftMapDetailsPage />} />}
              />
              <Route
                path="meu-perfil/:username"
                element={<ProtectedRoute element={<ProfilePage />} />}
              />
              <Route
                path="tema"
                element={<ProtectedRoute element={<Tema />} />}
              />
            </Routes>
          </Suspense>
        </Content>
        <DashboardFooter />
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
