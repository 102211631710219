import React, { useState, useEffect } from 'react';
import InputRelationTextArea from '../../../components/landingpage/InputStyled/InputRelation/InputRelationTextArea';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../../GiftMapPage.css';

const ValuesBeliefsStepPart2 = ({ onNext, onBack, value3: initialValue3, value4: initialValue4, setValue3, setValue4, isReturning }) => {
  const [value3, setLocalValue3] = useState(initialValue3 || '');
  const [value4, setLocalValue4] = useState(initialValue4 || '');
  const [typing, setTyping] = useState(false);
  const [showThirdInput, setShowThirdInput] = useState(false);
  const [showFourthText, setShowFourthText] = useState(false);
  const [showFourthInput, setShowFourthInput] = useState(false);
  const [thirdMessage, setThirdMessage] = useState('');
  const [fourthMessage, setFourthMessage] = useState('');

  const intro_value3 = "Se tiver, qual a religião?";
  const intro_value4 = "Qual são suas características culturais?";

  useEffect(() => {
    if (!isReturning) {
      const typeMessage = async (text, setMessageState, callback) => {
        for (let i = 0; i <= text.length; i++) {
          setMessageState(text.slice(0, i));
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
        if (callback) {
          callback();
        }
      };

      const animateText = async () => {
        setTyping(true);
        await typeMessage(intro_value3, setThirdMessage, () => {
          setShowThirdInput(true);
          setTyping(false);
        });

        setTimeout(() => {
          setTyping(true);
          setShowFourthText(true);
          typeMessage(intro_value4, setFourthMessage, () => {
            setShowFourthInput(true);
            setTyping(false);
          });
        }, 500);
      };

      animateText();
    } else {
      setThirdMessage(intro_value3);
      setFourthMessage(intro_value4);
      setShowThirdInput(true);
      setShowFourthText(true);
      setShowFourthInput(true);
    }
  }, [isReturning]);

  const isButtonDisabled = !(value3.trim().length > 0 && value4.trim().length > 0);

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        <h3>{thirdMessage}</h3>
        {showThirdInput && (
          <InputRelationTextArea
            placeholder="Descreva a religião, se houver"
            value={value3}
            onValueChange={(value) => {
              setLocalValue3(value);
              setValue3(value);
            }}
            rows={4}
          />
        )}
        {showFourthText && (
          <>
            <br />
            <h3>{fourthMessage}</h3>
          </>
        )}
        {showFourthInput && (
          <>
            <InputRelationTextArea
              placeholder="Descreva as características culturais"
              value={value4}
              onValueChange={(value) => {
                setLocalValue4(value);
                setValue4(value);
              }}
              rows={4}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon' /> Voltar
              </Button>
              <Button
                type="primary"
                onClick={onNext}
                className='btn-send'
                disabled={isButtonDisabled}
              >
                Continuar <ArrowRightOutlined className='btn-icon' />
              </Button>
            </div>
          </>
        )}
        {typing && <span className="typing-indicator">Digitando...</span>}
      </div>
    </div>
  );
};

export default ValuesBeliefsStepPart2;
