import React, { useState, useEffect } from 'react';
import InputRelationTextArea from '../../../components/landingpage/InputStyled/InputRelation/InputRelationTextArea';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../../GiftMapPage.css';

const ValuesBeliefsStepPart1 = ({ onNext, onBack, value1: initialValue1, value2: initialValue2, setValue1, setValue2, isReturning }) => {
  const [value1, setLocalValue1] = useState(initialValue1 || '');
  const [value2, setLocalValue2] = useState(initialValue2 || '');
  const [typing, setTyping] = useState(false);
  const [showFirstInput, setShowFirstInput] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);
  const [showSecondInput, setShowSecondInput] = useState(false);
  const [firstMessage, setFirstMessage] = useState('');
  const [secondMessage, setSecondMessage] = useState('');

  const intro_value1 = "Quais são os valores do presenteado?";
  const intro_value2 = "Quais suas ideologias e visão de mundo?";

  useEffect(() => {
    if (!isReturning) {
      const typeMessage = async (text, setMessageState, callback) => {
        for (let i = 0; i <= text.length; i++) {
          setMessageState(text.slice(0, i));
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
        if (callback) {
          callback();
        }
      };

      const animateText = async () => {
        setTyping(true);
        await typeMessage(intro_value1, setFirstMessage, () => {
          setShowFirstInput(true);
          setTyping(false);
        });

        setTimeout(() => {
          setTyping(true);
          setShowSecondText(true);
          typeMessage(intro_value2, setSecondMessage, () => {
            setShowSecondInput(true);
            setTyping(false);
          });
        }, 500);
      };

      animateText();
    } else {
      setFirstMessage(intro_value1);
      setSecondMessage(intro_value2);
      setShowFirstInput(true);
      setShowSecondText(true);
      setShowSecondInput(true);
    }
  }, [isReturning]);

  const isButtonDisabled = !(value1.trim().length > 0 && value2.trim().length > 0);

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        <h3>{firstMessage}</h3>
        {showFirstInput && (
          <InputRelationTextArea
            placeholder="Descreva os valores do presenteado"
            value={value1}
            onValueChange={(value) => {
              setLocalValue1(value);
              setValue1(value);
            }}
            rows={4}
          />
        )}
        {showSecondText && (
          <>
            <br />
            <h3>{secondMessage}</h3>
          </>
        )}
        {showSecondInput && (
          <>
            <InputRelationTextArea
              placeholder="Descreva as ideologias e visão de mundo"
              value={value2}
              onValueChange={(value) => {
                setLocalValue2(value);
                setValue2(value);
              }}
              rows={4}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon' /> Voltar
              </Button>
              <Button
                type="primary"
                onClick={onNext}
                className='btn-send'
                disabled={isButtonDisabled}
              >
                Continuar <ArrowRightOutlined className='btn-icon' />
              </Button>
            </div>
          </>
        )}
        {typing && <span className="typing-indicator">Digitando...</span>}
      </div>
    </div>
  );
};

export default ValuesBeliefsStepPart1;
