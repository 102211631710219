import React, { useState, useEffect } from 'react';
import InputEmail from '../../components/landingpage/InputStyled/InputEmail/InputEmail';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../GiftMapPage.css';

const EmailStep = ({ onNext, onBack, name: fullName, setEmail, isReturning, email }) => {
  const [messageElements, setMessageElements] = useState([]);
  const [typing, setTyping] = useState(true);
  const [showInputEmail, setShowInputEmail] = useState(false);
  const [localEmail, setLocalEmail] = useState(email || '');
  const [isValidEmail, setIsValidEmail] = useState(false);

  // Extrai o primeiro nome do fullName
  const firstName = fullName.split(' ')[0];

  const introMessages = {
    part1: 'Ótimo te conhecer, ',
    part2: `${firstName || 'Amigo'}!`,  // Usa o primeiro nome ou 'Amigo' como valor padrão
    part3: 'Agora, me conta seu melhor e-mail.',
  };

  const { part1, part2, part3 } = introMessages;

  useEffect(() => {
    setLocalEmail(email || '');
  }, [email]);

  useEffect(() => {
    if (!isReturning) {
      setMessageElements([]);
      setTyping(true);

      const typeMessage = async (text, messageIndex, className) => {
        for (let i = 0; i <= text.length; i++) {
          setMessageElements((prev) => [
            ...prev.slice(0, messageIndex),
            React.createElement(
              'h3',
              { key: messageIndex, className: className },
              messageIndex === 0 ? (
                <>
                  {i <= part1.length ? (
                    <>
                      {part1.slice(0, i)}
                    </>
                  ) : (
                    <>
                      {part1}
                      <span className="intro-name-bold">{part2.slice(0, i - part1.length)}</span>
                    </>
                  )}
                </>
              ) : (
                text.slice(0, i)
              )
            ),
          ]);
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
      };

      const animateText = async () => {
        await typeMessage(part1 + part2, 0, 'intro-line1');
        await typeMessage(part3, 1, 'intro-part3');
        setTyping(false);
        setShowInputEmail(true);
      };

      animateText();
    } else {
      setMessageElements([
        React.createElement('h3', { key: 'intro-line1', className: 'intro-line1' }, part1 + part2),
        React.createElement('h3', { key: 'intro-part3', className: 'intro-part3' }, part3)
      ]);
      setTyping(false);
      setShowInputEmail(true);
    }
  }, [part1, part2, part3, isReturning]);

  useEffect(() => {
    // Atualize a validade do e-mail toda vez que o valor mudar
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(localEmail));
  }, [localEmail]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && isValidEmail) {
        setEmail(localEmail);
        onNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isValidEmail, localEmail, onNext, setEmail]);

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        {messageElements}
        {typing && <span className="typing-indicator">Digitando...</span>}
        {!typing && showInputEmail && (
          <>
            <InputEmail
              placeholder="Digite seu e-mail"
              value={localEmail}
              onValueChange={setLocalEmail}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon' /> Voltar 
              </Button>
              <Button type="primary" onClick={() => {
                if (isValidEmail) {
                  setEmail(localEmail);
                  onNext();
                }
              }} className='btn-send' disabled={!isValidEmail}>
                Continuar <ArrowRightOutlined className='btn-icon'/>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailStep;
