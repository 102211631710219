import React, { useState, useEffect } from 'react';
import InputName from '../../components/landingpage/InputStyled/InputName/InputName';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../GiftMapPage.css';

const NameStep = ({ onNext, onBack, name, setName, isReturning }) => {
  const [messageElements, setMessageElements] = useState([]);
  const [introNameTyping, setIntroNameTyping] = useState(false);
  const [showInputName, setShowInputName] = useState(false);
  const [localName, setLocalName] = useState(name || '');

  const intro_name = "Para te conhecer melhor, que tal você me dizer seu nome?";

  useEffect(() => {
    if (!isReturning) {
      setIntroNameTyping(true);
      const typeIntroName = (charIndex = 0) => {
        if (charIndex < intro_name.length) {
          setMessageElements([React.createElement('h3', { key: 'intro-name', className: 'intro-name' }, intro_name.slice(0, charIndex + 1))]);
          setTimeout(() => typeIntroName(charIndex + 1), 30);
        } else {
          setIntroNameTyping(false);
          setShowInputName(true);
        }
      };

      typeIntroName();
    } else {
      // Fade-in effect when returning and show the full text
      setMessageElements([React.createElement('h3', { key: 'intro-name', className: 'intro-name' }, intro_name)]);
      setIntroNameTyping(false);
      setShowInputName(true);
    }
  }, [intro_name, isReturning]);

  useEffect(() => {
    setLocalName(name || '');
  }, [name]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        if (localName.trim()) {
          setName(localName); // Atualiza o nome completo
          onNext();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [localName, onNext, setName]);

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        {messageElements}
        {introNameTyping && <span className="typing-indicator">Digitando...</span>}
        {!introNameTyping && showInputName && (
          <>
            <InputName
              placeholder="Digite seu nome completo"
              value={localName}
              onValueChange={setLocalName}
              maxLength={50}
            />
            <div className="button-container_v1">
              <Button type="primary" onClick={() => {
                setName(localName); // Atualiza o nome completo
                onNext();
              }} className='btn-send' disabled={localName.trim().length === 0}>
                Continuar <ArrowRightOutlined className='btn-icon'/>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NameStep;
