import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  DashboardOutlined,
  GiftOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

const DashboardSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  // Defina a chave do item selecionado com base na rota atual
  const selectedKey = () => {
    const path = location.pathname;
    if (path === '/dashboard/pagina-inicial') return '0';
    if (path === '/dashboard/mapa-presente') return '1';
    if (path === '/dashboard/tema') return '2';
    return '';
  };

  // Configurando os itens do Menu
  const menuItems = [
    {
      key: '0',
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard/pagina-inicial">Página Inicial</Link>,
    },
    {
      key: '1',
      icon: <GiftOutlined />,
      label: <Link to="/dashboard/mapa-presente">Mapa de Presente</Link>,
    },
  ];

  // const configMenuItems = [
  //   {
  //     key: '2',
  //     icon: <DashboardOutlined />,
  //     label: <Link to="/dashboard/tema">Tema</Link>,
  //   },
  // ];

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed} className='SideBar'>
      <div className="logo">
        <img
          src={collapsed ? "/logo_pequena.webp" : "/logo.webp"}
          alt="Mimoravel Logo"
          className="logo-img"
        />
      </div>
      <div className="menu-section-title">Menu</div>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[selectedKey()]}
        items={menuItems}
      />
      {/* <div className="menu-section-title">{collapsed ? "Config" : "Configurações"}</div> */}
      {/* <Menu
        theme="light"
        mode="inline"
        items={configMenuItems}
      /> */}
    </Sider>
  );
};

export default DashboardSidebar;
