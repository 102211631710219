import React, { useState } from 'react'; // Importa useState
import { Form, Input, Button, Typography, Row, Col, message } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined, IdcardOutlined, MailOutlined } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input';
import { createUser } from '../../../services/userService';
import './auth.css';

const { Title } = Typography;

const RegisterPage = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false); // Adiciona o estado loading

    const onFinish = async (values) => {
        setLoading(true); // Ativa o estado de loading
        try {
            await createUser(values);
            form.resetFields();
            message.success('Usuário criado com sucesso!');
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.error;
                if (errorMessage.includes('CPF')) {
                    message.error('Este CPF já está cadastrado.');
                } else if (errorMessage.includes('E-mail')) {
                    message.error('Este e-mail já está cadastrado.');
                } else {
                    message.error('Ocorreu um erro ao criar o usuário.');
                }
            } else {
                message.error('Ocorreu um erro no servidor.');
            }
        } finally {
            setLoading(false); // Desativa o estado de loading
        }
    };

    return (
        <Row className="register-container" align="middle" justify="center">
            <Col>
                <div className="register-logo">
                    <img src="/logo.webp" alt="Mimoravel Logo" />
                </div>
                <div className="register-box">
                    <Title level={3} className="register-title">Criar sua conta</Title>
                    <Form
                        form={form}
                        name="register_form"
                        className="register-form"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="nome_completo"
                            rules={[{ required: true, message: 'Por favor, insira seu nome completo!' }]}
                        >
                            <Input prefix={<UserOutlined />} placeholder="Nome Completo" />
                        </Form.Item>

                        <Form.Item
                            name="cpf"
                            rules={[{ required: true, message: 'Por favor, insira seu CPF!' }]}
                        >
                            <MaskedInput
                                prefix={<IdcardOutlined />}
                                mask="000.000.000-00"
                                placeholder="CPF"
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Por favor, insira seu e-mail!' },
                                { type: 'email', message: 'Insira um e-mail válido!' },
                            ]}
                        >
                            <Input prefix={<MailOutlined />} placeholder="Email" />
                        </Form.Item>

                        <Form.Item
                            name="telefone"
                            rules={[{ required: true, message: 'Por favor, insira seu telefone!' }]}
                        >
                            <MaskedInput
                                prefix={<PhoneOutlined />}
                                mask="(00) 00000-0000"
                                placeholder="Telefone"
                            />
                        </Form.Item>

                        <Form.Item
                            name="senha"
                            rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Senha" />
                        </Form.Item>

                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                className="register-form-button"
                                loading={loading} // Adiciona o estado de loading
                            >
                                Registrar
                            </Button>
                        </Form.Item>
                        <div className="form-footer">
                            <span className='font-weight-400'>Já tem uma conta? <a href="/painel/login" className='font-weight-600'>Acessar</a></span>
                        </div>
                    </Form>
                </div>
            </Col>
        </Row>
    );
};

export default RegisterPage;
