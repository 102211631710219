import React from 'react';
import { Layout } from 'antd';
import UserMenu from './UserMenu';
import NotificationIcon from './Notifications';  // Importe o novo componente

const { Header } = Layout;

const DashboardHeader = () => {
  return (
    <Header className="dashboard-header">
      <div className="header-content">
        {/* <NotificationIcon /> */}
        <UserMenu />
      </div>
    </Header>
  );
};

export default DashboardHeader;
