import React, { useState } from 'react';
import { Input } from 'antd';
import './InputEmail.css';

const InputEmail = ({ placeholder, onValueChange, value }) => {
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const email = e.target.value;
    onValueChange(email);
    if (validateEmail(email)) {
      setError('');
    } else {
      setError('Por favor, insira um e-mail válido.');
    }
  };

  return (
    <div className="inputemail-container">
      <div className="input-wrapper">
        <Input
          className="input-field"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          aria-label="E-mail"
        />
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default InputEmail;
