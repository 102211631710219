import api from '../api/axios';

// Função para criar um usuário
export const createUser = async (userData) => {
  try {
    const response = await api.post('/users/register', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Função para autenticar um usuário
export const loginUser = async (email, senha) => {
  try {
    const response = await api.post('/users/login', { email, senha });
    return response.data; // Retorna os dados do usuário ou token
  } catch (error) {
    throw error;
  }
};

// Adicione outras funções conforme necessário
