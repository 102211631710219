import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GiftMapPage from '../pages/GiftMapPage';
import LoginPage from '../pages/Admin/auth/LoginPage';
import RegisterPage from '../pages/Admin/auth/RegisterPage';
import DashboardPage from '../pages/Admin/DashboardPage';

const AppRoutes = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <Routes>
            <Route path="/" element={<GiftMapPage />} />
            <Route 
                path="/painel/login" 
                element={
                    user ? <Navigate to="/dashboard/pagina-inicial" /> : <LoginPage />
                }
            />
            <Route 
                path="/painel/register" 
                element={
                    user ? <Navigate to="/dashboard/pagina-inicial" /> : <RegisterPage />
                }
            />
            <Route
                path="/dashboard/*"
                element={
                    user ? <DashboardPage /> : <Navigate to="/painel/login" />
                }
            />
        </Routes>
    );
};

export default AppRoutes;
