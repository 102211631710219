import React, { useState } from 'react';
import IntroStep from './steps/IntroStep';
import NameStep from './steps/NameStep';
import EmailStep from './steps/EmailStep';
import PhoneStep from './steps/PhoneStep';
import CityStep from './steps/CityStep';
// Questions
import OccasionStep from './steps/questions/OccasionStep';
import RelationStep from './steps/questions/RelationStep';
import InterestStep from './steps/questions/InterestStep';
import StylePersonalityStepPart1 from './steps/questions/StylePersonalityStepPart1';
import StylePersonalityStepPart2 from './steps/questions/StylePersonalityStepPart2';
import SharedMemoriesStep from './steps/questions/SharedMemoriesStep';
import ValuesBeliefsStepPart1 from './steps/questions/ValuesBeliefsStepPart1';
import ValuesBeliefsStepPart2 from './steps/questions/ValuesBeliefsStepPart2';
import UnfulfilledWishesStep from './steps/questions/UnfulfilledWishesStep';
import DislikesStep from './steps/questions/DislikesStep';
import './GiftMapPage.css';

const GiftMapPage = () => {
  const [step, setStep] = useState('intro');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [occasion, setOccasion] = useState('');
  const [relation1, setRelation1] = useState('');
  const [relation2, setRelation2] = useState('');
  const [interest1, setInterest1] = useState('');
  const [interest2, setInterest2] = useState('');
  const [style1, setStyle1] = useState('');
  const [style2, setStyle2] = useState('');
  const [style3, setStyle3] = useState('');
  const [style4, setStyle4] = useState('');
  const [memory1, setMemory1] = useState('');
  const [memory2, setMemory2] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [value4, setValue4] = useState('');
  const [wish, setWish] = useState('');
  const [dislikes, setDislikes] = useState('');
  const [isReturning, setIsReturning] = useState(false);

  const handleNext = () => {
    if (step === 'intro') {
      setStep('name');
    } else if (step === 'name') {
      setIsReturning(false);
      setStep('email');
    } else if (step === 'email') {
      setIsReturning(false);
      setStep('phone');
    } else if (step === 'phone') {
      setIsReturning(false);
      setStep('city');
    } else if (step === 'city') {
      setIsReturning(false);
      setStep('occasion');
    } else if (step === 'occasion') {
      setIsReturning(false);
      setStep('relation');
    } else if (step === 'relation') {
      setIsReturning(false);
      setStep('interest');
    } else if (step === 'interest') {
      setIsReturning(false);
      setStep('stylePersonalityPart1');
    } else if (step === 'stylePersonalityPart1') {
      setIsReturning(false);
      setStep('stylePersonalityPart2');
    } else if (step === 'stylePersonalityPart2') {
      setIsReturning(false);
      setStep('sharedMemories');
    } else if (step === 'sharedMemories') {
      setIsReturning(false);
      setStep('valuesBeliefsPart1');
    } else if (step === 'valuesBeliefsPart1') {
      setIsReturning(false);
      setStep('valuesBeliefsPart2');
    } else if (step === 'valuesBeliefsPart2') {
      setIsReturning(false);
      setStep('unfulfilledWishes');
    } else if (step === 'unfulfilledWishes') {
      setIsReturning(false);
      setStep('dislikes');
    } else if (step === 'dislikes') {
      setStep('completed');
      console.log('Nome:', name);
      console.log('E-mail:', email);
      console.log('Telefone:', phone);
      console.log('Cidade:', city);
      console.log('Ocasião:', occasion);
      console.log('Relação 1:', relation1);
      console.log('Relação 2:', relation2);
      console.log('Interesse 1:', interest1);
      console.log('Interesse 2:', interest2);
      console.log('Estilo 1:', style1);
      console.log('Estilo 2:', style2);
      console.log('Estilo 3:', style3);
      console.log('Estilo 4:', style4);
      console.log('Memória 1:', memory1);
      console.log('Memória 2:', memory2);
      console.log('Valor 1:', value1);
      console.log('Valor 2:', value2);
      console.log('Valor 3:', value3);
      console.log('Valor 4:', value4);
      console.log('Desejo não atendido:', wish);
      console.log('Descartes:', dislikes);
    }
  };

  const handleBack = () => {
    if (step === 'name') {
      setStep('intro');
      setIsReturning(true);
    } else if (step === 'email') {
      setStep('name');
      setIsReturning(true);
    } else if (step === 'phone') {
      setStep('email');
      setIsReturning(true);
    } else if (step === 'city') {
      setStep('phone');
      setIsReturning(true);
    } else if (step === 'occasion') {
      setStep('city');
      setIsReturning(true);
    } else if (step === 'relation') {
      setStep('occasion');
      setIsReturning(true);
    } else if (step === 'interest') {
      setStep('relation');
      setIsReturning(true);
    } else if (step === 'stylePersonalityPart1') {
      setStep('interest');
      setIsReturning(true);
    } else if (step === 'stylePersonalityPart2') {
      setStep('stylePersonalityPart1');
      setIsReturning(true);
    } else if (step === 'sharedMemories') {
      setStep('stylePersonalityPart2');
      setIsReturning(true);
    } else if (step === 'valuesBeliefsPart1') {
      setStep('sharedMemories');
      setIsReturning(true);
    } else if (step === 'valuesBeliefsPart2') {
      setStep('valuesBeliefsPart1');
      setIsReturning(true);
    } else if (step === 'unfulfilledWishes') {
      setStep('valuesBeliefsPart2');
      setIsReturning(true);
    } else if (step === 'dislikes') {
      setStep('unfulfilledWishes');
      setIsReturning(true);
    }
  };

  return (
    <div className="giftmap-page">
      <div className="prompt-section">
        <img src="/logo.webp" alt="Logo" className="form-logo" />
        {/* <div className="prompt-box">
          <h3>Write a prompt</h3>
          <p>
            Can you go to
            <span className="highlight"> Account Website </span>
            and find out if they are hiring for data analyst roles. If they are,
            please list the number of open roles.
          </p>
        </div> */}

        {/* <blockquote className="testimonial">
          <p>"Seam is building the future of AI for business teams. It really feels like we've entered into a new era for growth."</p>
          <footer>Conrad M.<br />Head of Marketing Ops, Rippling</footer>
        </blockquote> */}
      </div>

      <div className="form-section">
        {step === 'intro' && <IntroStep onNext={handleNext} />}
        {step === 'name' && <NameStep onNext={handleNext} onBack={handleBack} name={name} setName={setName} isReturning={isReturning} />}
        {step === 'email' && <EmailStep onNext={handleNext} onBack={handleBack} name={name} setEmail={setEmail} isReturning={isReturning} email={email} />}
        {step === 'phone' && <PhoneStep onNext={handleNext} onBack={handleBack} phone={phone} setPhone={setPhone} email={email} isReturning={isReturning} />}
        {step === 'city' && <CityStep onNext={handleNext} onBack={handleBack} city={city} setCity={setCity} isReturning={isReturning} />}
        {step === 'occasion' && <OccasionStep onNext={handleNext} onBack={handleBack} occasion={occasion} setOccasion={setOccasion} isReturning={isReturning} />}
        {step === 'relation' && <RelationStep onNext={handleNext} onBack={handleBack} relation1={relation1} relation2={relation2} setRelation1={setRelation1} setRelation2={setRelation2} isReturning={isReturning} />}
        {step === 'interest' && <InterestStep onNext={handleNext} onBack={handleBack} interest1={interest1} interest2={interest2} setInterest1={setInterest1} setInterest2={setInterest2} isReturning={isReturning} />}
        {step === 'stylePersonalityPart1' && <StylePersonalityStepPart1 onNext={handleNext} onBack={handleBack} style1={style1} style2={style2} setStyle1={setStyle1} setStyle2={setStyle2} isReturning={isReturning} />}
        {step === 'stylePersonalityPart2' && <StylePersonalityStepPart2 onNext={handleNext} onBack={handleBack} style3={style3} style4={style4} setStyle3={setStyle3} setStyle4={setStyle4} isReturning={isReturning} />}
        {step === 'sharedMemories' && <SharedMemoriesStep onNext={handleNext} onBack={handleBack} memory1={memory1} memory2={memory2} setMemory1={setMemory1} setMemory2={setMemory2} isReturning={isReturning} />} {/* Add Shared Memories Step */}
        {step === 'valuesBeliefsPart1' && (
          <ValuesBeliefsStepPart1
            onNext={handleNext}
            onBack={handleBack}
            value1={value1}
            value2={value2}
            setValue1={setValue1}
            setValue2={setValue2}
            isReturning={isReturning}
          />
        )}
        {step === 'valuesBeliefsPart2' && (
          <ValuesBeliefsStepPart2
            onNext={handleNext}
            onBack={handleBack}
            value3={value3}
            value4={value4}
            setValue3={setValue3}
            setValue4={setValue4}
            isReturning={isReturning}
          />
        )}
        {step === 'unfulfilledWishes' && (
          <UnfulfilledWishesStep
            onNext={handleNext}
            onBack={handleBack}
            wish={wish}
            setWish={setWish}
            isReturning={isReturning}
          />
        )}
        {step === 'dislikes' && (
          <DislikesStep
            onNext={handleNext}
            onBack={handleBack}
            dislikes={dislikes}
            setDislikes={setDislikes}
            isReturning={isReturning}
          />
        )}

        {step === 'completed' && (
          <div>
            <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
          <h1 className='textGlobalItem'>Obrigado, {name.split(' ')[0]}!</h1>
          <p>
            Acabei de receber suas respostas e posso dizer que sua dedicação ao escolher o presente ideal já está fazendo toda a diferença!
          </p>
          <p>
            Aqui no Mimorável, eu e minha equipe vamos mergulhar de cabeça para encontrar a solução perfeita. Afinal, quem você quer presentear merece nada menos que o melhor presente do mundo. 
          </p>
          <p>
            Vamos usar toda a nossa criatividade para elaborar uma estratégia incrível e garantir que este presente seja memorável!
          </p>
        </div>        
        )}
      </div>
    </div>
  );
};

export default GiftMapPage;
