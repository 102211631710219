import React, { useState, useEffect } from 'react';
import InputRelationTextArea from '../../../components/landingpage/InputStyled/InputRelation/InputRelationTextArea';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../../GiftMapPage.css';

const SharedMemoriesStep = ({ onNext, onBack, memory1: initialMemory1, memory2: initialMemory2, setMemory1, setMemory2, isReturning }) => {
  const [memory1, setLocalMemory1] = useState(initialMemory1 || '');
  const [memory2, setLocalMemory2] = useState(initialMemory2 || '');
  const [typing, setTyping] = useState(false);
  const [showFirstInput, setShowFirstInput] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);
  const [showSecondInput, setShowSecondInput] = useState(false);
  const [firstMessage, setFirstMessage] = useState('');
  const [secondMessage, setSecondMessage] = useState('');

  const intro_memory1 = "Você se recorda dos melhores momentos que passou com a pessoa que você quer presentear?";
  const intro_memory2 = "Quais foram esses momentos?";

  useEffect(() => {
    if (!isReturning) {
      const typeMessage = async (text, setMessageState, callback) => {
        for (let i = 0; i <= text.length; i++) {
          setMessageState(text.slice(0, i));
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
        if (callback) {
          callback();
        }
      };

      const animateText = async () => {
        setTyping(true);
        await typeMessage(intro_memory1, setFirstMessage, () => {
          setShowFirstInput(true);
          setTyping(false);
        });

        setTimeout(() => {
          setTyping(true);
          setShowSecondText(true);
          typeMessage(intro_memory2, setSecondMessage, () => {
            setShowSecondInput(true);
            setTyping(false);
          });
        }, 500);
      };

      animateText();
    } else {
      setFirstMessage(intro_memory1);
      setSecondMessage(intro_memory2);
      setShowFirstInput(true);
      setShowSecondText(true);
      setShowSecondInput(true);
    }
  }, [isReturning]);

  const isButtonDisabled = !(memory1.trim().length > 0 && memory2.trim().length > 0);

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        <h3>{firstMessage}</h3>
        {showFirstInput && (
          <InputRelationTextArea
            placeholder="Descreva os melhores momentos"
            value={memory1}
            onValueChange={(value) => {
              setLocalMemory1(value);
              setMemory1(value);
            }}
            rows={4}
          />
        )}
        {showSecondText && (
          <>
            <br />
            <h3>{secondMessage}</h3>
          </>
        )}
        {showSecondInput && (
          <>
            <InputRelationTextArea
              placeholder="Descreva esses momentos"
              value={memory2}
              onValueChange={(value) => {
                setLocalMemory2(value);
                setMemory2(value);
              }}
              rows={4}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon' /> Voltar
              </Button>
              <Button
                type="primary"
                onClick={onNext}
                className='btn-send'
                disabled={isButtonDisabled}
              >
                Continuar <ArrowRightOutlined className='btn-icon' />
              </Button>
            </div>
          </>
        )}
        {typing && <span className="typing-indicator">Digitando...</span>}
      </div>
    </div>
  );
};

export default SharedMemoriesStep;