import React, { useState, useEffect } from 'react';
import InputOccasion from '../../../components/landingpage/InputStyled/InputOccasion/InputOccasion';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../../GiftMapPage.css';

const OccasionStep = ({ onNext, onBack, occasion: initialOccasion, setOccasion, isReturning }) => {
  const [localOccasion, setLocalOccasion] = useState(initialOccasion || '');
  const [isValidOccasion, setIsValidOccasion] = useState(false);
  const [messageElements, setMessageElements] = useState([]);
  const [typing, setTyping] = useState(true);
  const [showInputOccasion, setShowInputOccasion] = useState(false);

  const introOccasion = "Qual a ocasião que está levando você a procurar presentes?";

  useEffect(() => {
    if (!isReturning) {
      setMessageElements([]);
      setTyping(true);

      const typeMessage = async (text, className) => {
        for (let i = 0; i <= text.length; i++) {
          setMessageElements([
            React.createElement('h3', { key: 'intro-occasion', className: className }, text.slice(0, i)),
          ]);
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
      };

      const animateText = async () => {
        await typeMessage(introOccasion, 'intro-occasion');
        setTyping(false);
        setShowInputOccasion(true);
      };

      animateText();
    } else {
      setMessageElements([
        React.createElement('h3', { key: 'intro-occasion', className: 'intro-occasion' }, introOccasion)
      ]);
      setTyping(false);
      setShowInputOccasion(true);
    }
  }, [introOccasion, isReturning]);

  useEffect(() => {
    setIsValidOccasion(localOccasion.trim().length > 0);
  }, [localOccasion]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isValidOccasion) {
        setOccasion(localOccasion); // Atualiza a ocasião no estado do componente pai
        onNext();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [localOccasion, isValidOccasion, onNext, setOccasion]);

  const handleOccasionChange = (value) => {
    setLocalOccasion(value);
    setIsValidOccasion(value.trim().length > 0);
  };

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        {messageElements}
        {typing && <span className="typing-indicator">Digitando...</span>}
        {!typing && showInputOccasion && (
          <>
            <InputOccasion
              placeholder="Digite a ocasião"
              value={localOccasion}
              onValueChange={handleOccasionChange}
            />
            <div className="button-container_v2">
              <Button type="default" onClick={onBack} className='btn-back'>
                <ArrowLeftOutlined className='btn-icon' /> Voltar 
              </Button>
              <Button type="primary" onClick={() => {
                if (isValidOccasion) {
                  setOccasion(localOccasion);
                  onNext();
                }
              }} className='btn-send' disabled={!isValidOccasion}>
                Continuar <ArrowRightOutlined className='btn-icon'/>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OccasionStep;
