import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const DashboardFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Footer className="dashboard-footer">
      <div className="footer-content">
        {currentYear} © Mimoravel - Todos os direitos reservados <br/>
        Versão v1.0 - Desenvolvido por <a>Revolck</a>
      </div>
    </Footer>
  );
};

export default DashboardFooter;
