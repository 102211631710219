import React, { useState, useEffect } from 'react';
import InputRelationTextArea from '../../../components/landingpage/InputStyled/InputRelation/InputRelationTextArea';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../../GiftMapPage.css';

const UnfulfilledWishesStep = ({ onNext, onBack, wish: initialWish, setWish, isReturning }) => {
  const [wish, setLocalWish] = useState(initialWish || '');
  const [typing, setTyping] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [message, setMessage] = useState('');

  const intro_message = "Há algo que a pessoa já disse que queria ganhar, mas ainda não ganhou? Se você sabe, descreva o que é.";

  useEffect(() => {
    if (!isReturning) {
      const typeMessage = async (text, setMessageState, callback) => {
        for (let i = 0; i <= text.length; i++) {
          setMessageState(text.slice(0, i));
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
        if (callback) {
          callback();
        }
      };

      const animateText = async () => {
        setTyping(true);
        await typeMessage(intro_message, setMessage, () => {
          setShowInput(true);
          setTyping(false);
        });
      };

      animateText();
    } else {
      setMessage(intro_message);
      setShowInput(true);
    }
  }, [isReturning]);

  const isButtonDisabled = !(wish.trim().length > 0);

  return (
    <div className={`message-wrapper ${isReturning ? 'fade-in' : ''}`}>
      <div className="dotlottie-container">
        <dotlottie-player
          src="https://lottie.host/f46b6bdd-327d-4e84-ae0b-44d88b722fda/0Cb43DjUjT.json"
          background="transparent"
          speed="1"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <div className="message-text">
        <h3>{message}</h3>
        {showInput && (
          <InputRelationTextArea
            placeholder="Descreva o desejo ainda não atendido"
            value={wish}
            onValueChange={(value) => {
              setLocalWish(value);
              setWish(value);
            }}
            rows={4}
          />
        )}
        {showInput && (
          <div className="button-container_v2">
            <Button type="default" onClick={onBack} className='btn-back'>
              <ArrowLeftOutlined className='btn-icon' /> Voltar
            </Button>
            <Button
              type="primary"
              onClick={onNext}
              className='btn-send'
              disabled={isButtonDisabled}
            >
              Continuar <ArrowRightOutlined className='btn-icon' />
            </Button>
          </div>
        )}
        {typing && <span className="typing-indicator">Digitando...</span>}
      </div>
    </div>
  );
};

export default UnfulfilledWishesStep;
